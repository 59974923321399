import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalBody, ModalHeader, Button} from 'reactstrap';
import TableGenerator from '../../../../../components/TableGenerator/TableGenerator';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import CheckedImage from '../../../../../assets/imgs/checked.png';
import UncheckedImage from '../../../../../assets/imgs/unchecked.png';
import * as actions from '../../../../../store/actions';

class SelectSurveysModal extends Component {
  state = {
    participants: [],
    searchedParticipants: [],
    search: '',
    isSelectAll: false,
  };

  componentWillMount() {
    const initialSurveys = this.props.surveys
      .filter(survey => survey.surveyStatus === 'Completed')
      .map((survey, index) => {
        survey.checked = false;
        survey.position = index;
        return survey;
      });
    this.setState({ surveys: initialSurveys });
  }

  handleStatusChange = value => {
    console.log(value);
    this.setState({ status: value });
  };

  handleSubmit = () => {
    const surveys = this.state.surveys
      .filter(survey => survey.checked === true)
      .map(survey => {
        return survey.surveyId;
      });
    this.props.downloadSurveys(surveys);
  };

  handleClick = index => {
    console.log(index);
    const getClickedParticipant = () => {
      const survey = this.state.surveys.map(survey => {
        if (survey.position === index) {
          survey.checked = !survey.checked;
        }
        return survey;
      });
      this.setState({ survey, isSelectAll: false });
    };
    getClickedParticipant();
  };

  generateTableData = () => {
    const result = this.state.surveys.map(survey => ({
      name: `${survey.surveyName}`,
      selected: <img className="checkbox  border border-dark rounded" style={{ cursor: 'pointer' }} alt="Checkbox" src={survey.checked ? CheckedImage : UncheckedImage} />,
    }));
    return result;
  };

  handleIsSelectAll = () => {
    this.setState({
      isSelectAll: !this.state.isSelectAll,
      surveys: this.state.surveys.map(survey => {
        survey.checked = !this.state.isSelectAll;
        return survey;
      }),
    });
  };

  render() {
    return (
      <ModalWrapper className="survey-status-modal-wrapper" show={this.props.show} onExited={this.props.closeModal}>
        <ModalHeader toggle={this.props.closeModal}>
            Bulk Download Assessments
        </ModalHeader>
        <ModalBody bsPrefix="modalContent survey-status-modal">
          <label className="label">List</label>
          <CheckBox
            className="select-all-invite-container"
            labelClasses={['select-all-invite']}
            label="Select All"
            name="Select All"
            value="Select All"
            isChecked={this.state.isSelectAll}
            onClick={this.handleIsSelectAll}
          />
          <div className="modal-table-container">
            <TableGenerator
              config={{
                headers: ['SURVEY NAME', ''],
                contentRows: this.generateTableData(),
                rowFunction: this.handleClick,
              }}
            />
          </div>
        
          <div className="btnContainer-edit-participant">
            <Button onClick={() => this.handleSubmit()} type="submit" className="modalBtn" size="md" color="success">
              Download Assessments
            </Button>
            <Button onClick={this.props.closeModal} className="modalBtn" size="md" variant="outline-primary">
              Cancel
            </Button>
          </div>
        </ModalBody>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = state => {
  return { isLoading: state.isLoading };
};

const mapDispatchToProps = dispatch => {
  return {
    downloadSurveys: surveyListIds => dispatch(actions.downloadSurveys(surveyListIds)),
    closeModal: () => dispatch(actions.closeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectSurveysModal);
