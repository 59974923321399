/* eslint-disable no-useless-escape */
import React from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { groupBy } from 'lodash';
import axios from '../../../utilities/http';
import { openModal, closeModal } from '../modalConductor/modalConductor';
import { openToaster } from '../toaster/toaster';
import * as actionTypes from '../actionTypes';
// import { getOrganization } from '../organizations/organizations';
import { listResponders } from '../users/users';
import getSchoolAndDistrict from '../../../utilities/getSchoolAndDistrict';

const { REACT_APP_API_URL } = process.env;

export const clearCreateSurveyState = () => {
  return {
    type: actionTypes.CLEAR_CREATE_SURVEY_STATE,
  };
};

export const toggleCheckedIndicatorInForm = indicatorId => {
  return {
    type: actionTypes.TOGGLE_CHECKED_INDICATOR_IN_FORM,
    indicatorId,
  };
};

export const setAppliedToValue = value => {
  return {
    type: actionTypes.SET_APPLIED_TO,
    appliedTo: value,
  };
};

export const setParticipantValues = participantData => {
  return {
    type: actionTypes.SET_PARTICIPANT_VALUES,
    participantData: participantData,
  };
};

export const updateParticipantStarted = value => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_STARTED,
    value,
  };
};

export const toggleParticipantContacted = value => {
  return {
    type: actionTypes.TOGGLE_PARTICIPANT_CONTACTED,
    value: value,
  };
};

export const setAppliedTo = value => {
  return (dispatch, getState) => {
    const indicators = getState().surveyCrud.indicators;
    dispatch(setAppliedToValue(value));
    dispatch(setIndicatorsByCategoryAndType(indicators, value));
    dispatch(resetChosenIndicators());
  };
};

export const resetChosenIndicators = () => {
  // console.log(`resetting chosen indicators`)
  return {
    type: actionTypes.SET_CHOSEN_INDICATORS,
    chosenIndicators: [],
  };
};

export const setChosenIndicators = chosenIndicators => {
  return {
    type: actionTypes.SET_CHOSEN_INDICATORS,
    chosenIndicators,
  };
};

export const setProfession = value => {
  return {
    type: actionTypes.SET_PROFESSION,
    profession: value,
  };
};

export const toggleCategoryIndicators = category => {
  return (dispatch, getState) => {
    // get indicatorIdsByCategory
    const indicatorIdsByCategory = getState().surveyCrud.indicatorIdsByCategory;

    // get createSurveyObj.chosenIndicators
    const chosenIndicators = getState().surveyCrud.createSurveyObj.chosenIndicators;
    // Determine if values need added or removed by checking if all the categories indicators are already added
    const hasAllValues = indicatorIdsByCategory[category].every(elem => chosenIndicators.indexOf(elem) > -1);

    if (hasAllValues) {
      indicatorIdsByCategory[category].forEach(item => {
        const itemIndex = chosenIndicators.indexOf(item);
        if (itemIndex >= 0) {
          chosenIndicators.splice(itemIndex, 1);
        }
      });
      const newChosenIndicators = [...new Set(chosenIndicators)];
      dispatch(setChosenIndicators(newChosenIndicators));
    } else {
      indicatorIdsByCategory[category].forEach(item => {
        if (item !== "44b68c8d-b49d-460e-9668-a3a4185bcb23") // do not push self harm
          chosenIndicators.push(item)
      }
      );
      const newChosenIndicators = [...new Set(chosenIndicators)];
      dispatch(setChosenIndicators(newChosenIndicators));
    }
  };
};

export const setSurveyQuarter = value => {
  return {
    type: actionTypes.SET_SURVEY_QUARTER,
    quarter: value,
  };
};

export const setDesignatedEmailRecipient = value => {
  return {
    type: actionTypes.SET_DESIGNATED_EMAIL_RECIPIENT,
    designatedRecipient: value,
  };
};

export const setSurveyNameSelect = value => {
  return {
    type: actionTypes.SET_SURVEY_NAME_SELECT,
    surveyName: value,
  };
};

export const setWellnessCenterCheck = value => {
  return {
    type: actionTypes.SET_WELLNESS_CENTER_CHECK,
    wellnessCenterCheck: value,
  };
};

export const setFixedOrder = value => {
  return {
    type: actionTypes.SET_FIXED_ORDER,
    fixedOrder: value,
  };
};

export const setSurveyYear = value => {
  return {
    type: actionTypes.SET_SURVEY_YEAR,
    year: value,
  };
};

export const setSurveyOrgId = value => {
  return {
    type: actionTypes.SET_SURVEY_ORGID,
    orgId: value,
  };
};

export const hideSurveySearch = () => {
  return {
    type: actionTypes.HIDE_SURVEY_SEARCH,
  };
};

export const showSurveySearch = () => {
  return {
    type: actionTypes.SHOW_SURVEY_SEARCH,
  };
};

export const clearStateOptions = () => {
  return {
    type: actionTypes.CLEAR_STATE_OPTION,
  };
};

export const clearDistrictOptions = () => {
  return {
    type: actionTypes.CLEAR_DISTRICT_OPTION,
  };
};

export const clearSchoolOptions = () => {
  return {
    type: actionTypes.CLEAR_SCHOOL_OPTION,
  };
};

export const setSearchValues = () => {
  return (dispatch, getState) => {
    dispatch(setSearchValuesStart());
    const url = `${REACT_APP_API_URL}/search?country=${getState().surveyCrud.surveySearchCountryRegion}&state=${getState().surveyCrud.surveySearchStateProvince}&district=${getState().surveyCrud.surveySearchDistrict && getState().surveyCrud.surveySearchDistrict.orgId ? getState().surveyCrud.surveySearchDistrict.orgId : ''
      }`;
    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        dispatch(setSearchValuesSuccess(res.data));
      })
      .catch(err => {
        dispatch(setSearchValuesFail(err));
        console.log('err', err);
      });
  };
};

export const setSearchValuesStart = () => {
  return {
    type: actionTypes.SET_SEARCH_VALUES_START,
  };
};

export const setSearchValuesFail = err => {
  return {
    type: actionTypes.SET_SEARCH_VALUES_FAIL,
    err,
  };
};

export const setSearchValuesSuccess = values => {
  return {
    type: actionTypes.SET_SEARCH_VALUES,
    values,
  };
};

export const submitSurveySearchStart = () => {
  return {
    type: actionTypes.SUBMIT_SURVEY_SEARCH_START,
  };
};

export const submitSurveySearchSuccess = () => {
  return {
    type: actionTypes.SUBMIT_SURVEY_SEARCH_SUCCESS,
  };
};

export const submitSurveySearchFail = () => {
  return {
    type: actionTypes.SUBMIT_SURVEY_SEARCH_FAIL,
  };
};

export const listIndicatorsStart = () => {
  return {
    type: actionTypes.LIST_INDICATORS_START,
  };
};

export const listIndicatorsSuccess = indicators => {
  indicators.sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : a.orderIndex === b.orderIndex ? (a.indicator > b.indicator ? 1 : -1) : -1));
  return {
    type: actionTypes.LIST_INDICATORS_SUCCESS,
    indicators,
  };
};

export const listProfessionsSuccess = professions => {
  return {
    type: actionTypes.LIST_PROFESSIONS_SUCCESS,
    professions,
  };
};

export const listIndicatorsFail = err => {
  return {
    type: actionTypes.LIST_INDICATORS_FAIL,
    err,
  };
};

export const listIndicators = () => {
  return (dispatch, getState) => {
    dispatch(listIndicatorsStart());
    const url = `${REACT_APP_API_URL}/survey/indicators?v2=true`;
    const appliedTo = getState().surveyCrud.createSurveyObj.appliedTo;
    //dispatch(listIndicatorsSuccess(indicatorsList));

    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        dispatch(listIndicatorsSuccess(res.data.indicators));
        dispatch(listProfessionsSuccess(res.data.professions));
        dispatch(setIndicatorsByCategoryAndType(res.data.indicators, appliedTo));
      })
      .catch(err => {
        dispatch(listIndicatorsFail(err));
      });
  };
};

// name is unchanged but it only group by type
export const setIndicatorsByCategoryAndType = (indicators, appliedTo) => {
  const indicatorsList = indicators.filter(indicator => {
    return indicator.appliesTo.includes(appliedTo);
  });
  const indicatorsByType = groupBy(indicatorsList, 'type');
  // sort them by domain
  Object.keys(indicatorsByType).forEach(type => {
    indicatorsByType[type].sort((i, j) => i.domainId.localeCompare(j.domainId));
  });
  let indicatorsByTypeAndDomain = {};
  Object.keys(indicatorsByType).forEach(type => {
    indicatorsByTypeAndDomain[type] = groupBy(indicatorsByType[type], 'domainId');
  });

  // const indicatorsByCategoryAndType = groupBy(indicatorsList, 'category');
  // const indicatorsByCategory = groupBy(indicatorsList, 'category');
  // Object.keys(indicatorsByCategory)
  //   .sort()
  //   .forEach(group => {
  //     indicatorsByCategoryAndType[group] = groupBy(indicatorsByCategory[group], 'type');
  //   });

  const indicatorIdsByType = {};
  // eslint-disable-next-line array-callback-return
  Object.keys(indicatorsByType).map(key => {
    indicatorIdsByType[key] = indicatorsByType[key].map(item => item.indicatorId);
  });

  return {
    type: actionTypes.SET_INDICATORS_BY_CATEGORY_AND_TYPE,
    indicatorsByCategoryAndType: indicatorsByType,
    indicatorsByTypeAndDomain: indicatorsByTypeAndDomain,
    indicatorIdsByCategory: indicatorIdsByType,
  };
};

export const clearSurveyDetails = () => {
  return {
    type: actionTypes.CLEAR_SURVEY_DETAILS,
  };
};

export const addToManualRosterLength = () => {
  return {
    type: actionTypes.ADD_TO_MANUAL_ROSTER_LENGTH,
  };
};

export const clearManualRoster = () => {
  return {
    type: actionTypes.CLEAR_MANUAL_ROSTER,
  };
};

export const uploadRosterStart = () => {
  return {
    type: actionTypes.UPLOAD_ROSTER_START,
  };
};

export const uploadRosterSuccess = (fileKey, ETag) => {
  return {
    type: actionTypes.UPLOAD_ROSTER_SUCCESS,
    fileKey,
    ETag,
  };
};

export const uploadRosterFail = err => {
  return {
    type: actionTypes.UPLOAD_ROSTER_FAIL,
    err,
  };
};

export const uploadRoster = (file, fileKey, signedUrl) => {
  return dispatch => {
    dispatch(uploadRosterStart());
    // Split the filename to get the name and type
    let fileParts = file.name.split('.');
    //let fileName = fileParts[0];
    let fileType = fileParts[1];
    //const fileKey = `district_school_${fileName}_${new Date().getTime()}.csv`;
    // var params = {
    //   Body: file,
    //   Bucket: process.env.REACT_APP_ROSTER_S3_BUCKET || 'tm-test-roster-2',
    //   Key: fileKey,
    //   ServerSideEncryption: 'AES256',
    //   Tagging: 'key1=value1&key2=value2',
    // };
    const options = {
      headers: {
        'Content-Type': fileType,
      },
    };
    axios
      .put(signedUrl, file, options)
      .then(res => {
        console.log('Response for uploadRoser: ', res);
        dispatch(uploadRosterSuccess(fileKey, ''));
      })
      .catch(err => {
        dispatch(uploadRosterFail(err));
      });
    // s3.putObject(params, function (err, data) {
    //   if (err) {
    //     console.log(err, err.stack); // an error occurred
    //     dispatch(uploadRosterFail(err));
    //   } else {
    //     console.log('s3Response: ', data); // successful response
    //     dispatch(uploadRosterSuccess(fileKey, data.ETag));
    //   }
    //   /*
    //   data = {
    //     ETag: "\"6805f2cfc46c0f04559748bb039d69ae\"",
    //     ServerSideEncryption: "AES256",
    //     VersionId: "Ri.vC6qVlA4dEnjgRV4ZHsHoFIjqEMNt"
    //   }
    //   */
    // });
  };
};

export const getSignedUrlStart = () => {
  return {
    type: actionTypes.GET_SIGNED_URL_START,
  };
};

export const getSignedUrlSuccess = (fileKey, url) => {
  return {
    type: actionTypes.GET_SIGNED_URL_SUCCESS,
    fileKey,
    url,
  };
};

export const getSignedUrlFail = err => {
  return {
    type: actionTypes.GET_SIGNED_URL_FAIL,
    err,
  };
};

export const getSignedUrl = file => {
  return (dispatch, getState) => {
    dispatch(getSignedUrlStart());
    const parentOrgId = getState().organizations.organization.parentOrgId || '';
    const orgId = getState().organizations.organization.orgId;
    let fileParts = file.name.split('.');
    let fileName = fileParts[0].replace(/[^\w.-]/g, '_').toLowerCase();
    let fileKey = `${parentOrgId}_${orgId}_${fileName}_${new Date().getTime()}.csv`;
    const url = `${REACT_APP_API_URL}/presigned/${fileKey}`;
    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        console.log('Res from getSignedUrl', res);
        dispatch(getSignedUrlSuccess(fileKey, res.data.url));
        dispatch(uploadRoster(file, fileKey, res.data.url));
      })
      .catch(err => {
        dispatch(getSignedUrlFail(err));
      });
  };
};
export const getFormattedOrgsStart = () => {
  return {
    type: actionTypes.GET_FORMATTED_ORGS_START,
  };
};

export const getFormattedOrgsSuccess = orgs => {
  return {
    type: actionTypes.GET_FORMATTED_ORGS_SUCCESS,
    orgs,
  };
};

export const getFormattedOrgsFail = err => {
  return {
    type: actionTypes.GET_FORMATTED_ORGS_FAIL,
    err,
  };
};

export const getFormattedOrgs = () => {
  return (dispatch, getState) => {
    dispatch(getFormattedOrgsStart());

    const url = `${REACT_APP_API_URL}/orgs/formatted`;
    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        dispatch(getFormattedOrgsSuccess(res.data));
      })
      .catch(err => {
        dispatch(getFormattedOrgsFail(err));
      });
  };
};

export const assignResponderStart = () => {
  return {
    type: actionTypes.ASSIGN_RESPONDER_START,
  };
};

export const assignResponderSuccess = () => {
  return {
    type: actionTypes.ASSIGN_RESPONDER_SUCCESS,
  };
};

export const assignResponderFail = err => {
  return {
    type: actionTypes.ASSIGN_RESPONDER_FAIL,
    err,
  };
};

export const assignResponder = (orgIds, responderId) => {
  return (dispatch, getState) => {
    dispatch(assignResponderStart());
    const url = `${REACT_APP_API_URL}/orgs/assignResponder/${responderId}`;
    axios({
      url,
      method: 'PUT',
      data: { orgIds },
      headers: { Authorization: getState().auth.idToken },
    })
      .then(() => {
        dispatch(assignResponderSuccess());
        dispatch(closeModal());
        dispatch(openToaster('Successfully assigned the user.'));
        dispatch(listResponders());
      })
      .catch(err => {
        dispatch(assignResponderFail(err));
      });
  };
};

export const getResponderParticipantsStart = () => {
  return {
    type: actionTypes.GET_RESPONDER_PARTICIPANTS_START,
  };
};

export const getResponderParticipantsSuccess = surveyParticipants => {
  return {
    type: actionTypes.GET_RESPONDER_PARTICIPANTS_SUCCESS,
    surveyParticipants,
  };
};

export const getResponderParticipantsFail = err => {
  return {
    type: actionTypes.GET_RESPONDER_PARTICIPANTS_FAIL,
    err,
  };
};

export const getResponderParticipants = (responderId, timeOut = 0) => {
  return (dispatch, getState) => {
    dispatch(getResponderParticipantsStart());
    const url = `${REACT_APP_API_URL}/participants/responder/${responderId}`;
    setTimeout(() => {
      axios({
        url,
        method: 'GET',
        headers: { Authorization: getState().auth.idToken },
      })
        .then(res => {
          dispatch(getResponderParticipantsSuccess(res.data.participants));
        })
        .catch(err => {
          dispatch(getResponderParticipantsFail(err));
        });
    }, timeOut);
  };
};
export const getAssignedResponderParticipantsStart = () => {
  return {
    type: actionTypes.GET_ASSIGNED_RESPONDER_PARTICIPANTS_START,
  };
};

export const getAssignedResponderParticipantsSuccess = surveyParticipants => {
  console.log('action', surveyParticipants);
  return {
    type: actionTypes.GET_ASSIGNED_RESPONDER_PARTICIPANTS_SUCCESS,
    surveyParticipants,
  };
};

export const getAssignedResponderParticipantsFail = err => {
  return {
    type: actionTypes.GET_ASSIGNED_RESPONDER_PARTICIPANTS_FAIL,
    err,
  };
};

export const getAssignedResponderParticipants = (responderId, timeOut = 0) => {
  return (dispatch, getState) => {
    dispatch(getAssignedResponderParticipantsStart());
    const url = `${REACT_APP_API_URL}/responder/participants/${responderId}`;
    setTimeout(() => {
      axios({
        url,
        method: 'GET',
        headers: { Authorization: getState().auth.idToken },
      })
        .then(res => {
          console.log('survey participants received', res.data);
          dispatch(getAssignedResponderParticipantsSuccess(res.data.participants));
        })
        .catch(err => {
          dispatch(getAssignedResponderParticipantsFail(err));
        });
    }, timeOut);
  };
};

export const checkRosterUploadStatusSuccess = () => {
  return {
    type: actionTypes.CHECK_ROSTER_UPLOAD_STATUS_SUCCESS,
  };
};

export const checkRosterUploadStatusFail = () => {
  return {
    type: actionTypes.CHECK_ROSTER_UPLOAD_STATUS_FAIL,
  };
};

export const checkRosterUploadStatus = () => {
  return (dispatch, getState) => {
    const fileKey = getState().surveyCrud.createSurveyObj.s3Tags.fileKey;
    const url = `${REACT_APP_API_URL}/participant/rosterstatus/${fileKey}`;
    // wait on key to be propagated
    if (!fileKey) {
      return;
    }

    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        // status enum = ["processing", "error", "complete"]
        if (res.data.rosterStatus.toLowerCase() === 'complete') {
          dispatch(checkRosterUploadStatusSuccess());
        }

        if (res.data.rosterStatus.toLowerCase() === 'error') {
          dispatch(checkRosterUploadStatusFail());
          dispatch(
            openModal({
              modalType: 'GENERIC_MODAL',
              show: true,
              headline: 'Participant List Error',
              content: (
                <>
                  <span className="error-text">ERROR: </span>
                  <span>{res.data.message ? `${res.data.message}` : 'The participant list you submitted did not match the correct format.'}</span>
                </>
              ),
            }),
          );
        }
      })
      .catch(() => {
        dispatch(checkRosterUploadStatusFail());
      });
  };
};

export const listSurveyStart = () => {
  return {
    type: actionTypes.LIST_SURVEY_START,
  };
};

export const listSurveySuccess = (surveys, surveysListParam) => {
  return {
    type: actionTypes.LIST_SURVEY_SUCCESS,
    surveys,
    surveysListParam,
  };
};

export const listSurveyFail = err => {
  return {
    type: actionTypes.LIST_SURVEY_FAIL,
    err,
  };
};

export const listSurveys = ({ schoolId, districtId }) => {
  return (dispatch, getState) => {
    dispatch(listSurveyStart());
    const url = `${REACT_APP_API_URL}/surveys/?v2=true&districtId=${districtId}${schoolId ? '&schoolId=' + schoolId : ''}`;

    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        dispatch(listSurveySuccess(res.data.surveys, { schoolId, districtId }));
      })
      .catch(err => {
        dispatch(listSurveyFail(err));
      });
  };
};

// export const listSurveys = (values = {}) => {
//   const LIST_SURVEY_QUERY_KEYS = ['year', 'country', 'state', 'name', 'school', 'district'];
//   return (dispatch, getState) => {
//     dispatch(listSurveyStart());

//     const {
//       organizations: { organization: org },
//       auth: { role, idToken },
//     } = getState();

//     // eslint-disable-next-line no-unused-vars
//     const { year, country, state, name, school, district } = LIST_SURVEY_QUERY_KEYS.reduce((accum, key, _value) => {
//       return !values[key] ? { ...accum, [key]: '' } : { ...accum, [key]: values[key] };
//     }, {});
//     const getSchoolOrgId = () => {
//       if (org && org.orgId && role === 'school_champion') {
//         return org.orgId;
//       } else if (school) {
//         return school;
//       } else {
//         return '';
//       }
//     };

//     const getDistrictOrgId = () => {
//       if (org && org.parentOrg && org.parentOrg.orgId) {
//         return org.parentOrg.orgId;
//       } else {
//         if (org && org.orgId && role === 'district_champion') {
//           return org.orgId;
//         } else if (district) {
//           return district;
//         } else {
//           return '';
//         }
//       }
//     };
//     const url = `${REACT_APP_API_URL}/surveys/?year=${year}&country=${country}&state=${state}&districtId=${getDistrictOrgId()}&schoolId=${getSchoolOrgId()}&surveyName=${name}`;

//     axios({
//       url,
//       method: 'GET',
//       headers: { Authorization: idToken },
//     })
//       .then(res => {
//         dispatch(listSurveySuccess(res.data.surveys));
//       })
//       .catch(err => {
//         dispatch(listSurveyFail(err));
//       });
//   };
// };

// export const listSurveys = (values = {}) => {
//   const LIST_SURVEY_QUERY_KEYS = ['year', 'country', 'state', 'name', 'school', 'district'];
//   return (dispatch, getState) => {
//     dispatch(listSurveyStart());

//     const {
//       organizations,
//       auth: { idToken },
//     } = getState();

//     // eslint-disable-next-line no-unused-vars
//     const { year, country, state, name, school, district } = LIST_SURVEY_QUERY_KEYS.reduce((accum, key, _value) => {
//       return !values[key] ? { ...accum, [key]: '' } : { ...accum, [key]: values[key] };
//     }, {});

//     const districId = organizations?.districtSearchName || organizations?.organization?.orgId;
//     const schoolId = organizations?.schoolSearchName;

//     const url = `${REACT_APP_API_URL}/surveys/?year=${year}&country=${country}&state=${state}&districtId=${districId}&schoolId=${schoolId}&surveyName=${name}`;

//     axios({
//       url,
//       method: 'GET',
//       headers: { Authorization: idToken },
//     })
//       .then(res => {
//         dispatch(listSurveySuccess(res.data.surveys));
//       })
//       .catch(err => {
//         dispatch(listSurveyFail(err));
//       });
//   };
// };
export const archiveSurveysStart = () => {
  return {
    type: actionTypes.ARCHIVE_SURVEYS_START,
  };
};

export const archiveSurveysSuccess = () => {
  return {
    type: actionTypes.ARCHIVE_SURVEYS_SUCCESS,
  };
};

export const archiveSurveysFail = err => {
  return {
    type: actionTypes.ARCHIVE_SURVEYS_FAIL,
    err,
  };
};
export const archiveSurveys = (surveyIds, cb, isArchived = true) => {
  return (dispatch, getState) => {
    const url = `${REACT_APP_API_URL}/surveys`;
    const surveys = surveyIds.map(surveyId => {
      return { id: surveyId, isArchived: isArchived };
    });
    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data: { surveys: surveys },
    })
      .then(() => {
        dispatch(openToaster(`Successfully ${isArchived ? 'archived' : 'unarchived'} survey.`));
        if (cb) {
          cb();
        }
        dispatch(archiveSurveysSuccess());
      })
      .catch(err => {
        dispatch(archiveSurveysFail(err));
      });
  };
};

export const createSurveyStart = () => {
  return {
    type: actionTypes.CREATE_SURVEY_START,
  };
};

export const createSurveySuccess = () => {
  return {
    type: actionTypes.CREATE_SURVEY_SUCCESS,
  };
};

export const createSurveyFail = err => {
  return {
    type: actionTypes.CREATE_SURVEY_FAIL,
    err,
  };
};

export const updateSearchSurveyObj = payload => {
  return {
    type: actionTypes.UPDATE_SEARCH_SURVEY,
    payload,
  };
};

export const toggleGradeForSurvey = grade => {
  return {
    type: actionTypes.TOGGLE_GRADE_FOR_SURVEY,
    grade,
  };
};

export const setStudentsByGrade = (students, grades = null) => {
  return {
    type: actionTypes.SET_STUDENTS_BY_GRADE,
    students,
    grades,
  };
};

export const setTimestampsByGrade = timestamps => {
  return {
    type: actionTypes.SET_TIMESTAMPS_BY_GRADE,
    timestamps,
  };
};

export const createSurvey = (participants = [], cb = null) => {
  return (dispatch, getState) => {
    dispatch(createSurveyStart());
    const url = `${REACT_APP_API_URL}/survey`;
    // const orgId = getState().organizations.organization.orgId
    // verification that the orgId is correct, not sure that's necessary but just in case
    // console.log(orgId, getState().surveyCrud.createSurveyObj.orgId, schoolOrgId)
    // if (orgId !== getState().surveyCrud.createSurveyObj.orgId) throw Error("orgId mismatch! Aborting...")
    const createSurveyObj = getState().surveyCrud.createSurveyObj;
    const { schoolId, districtId } = getSchoolAndDistrict(getState().navigation.org);
    const { orgId, chosenIndicators, profession, quarter: { value: quarter }, designatedRecipient, wellnessCenterCheck, year: { value: year }, appliedTo, surveyName, fixedOrder } = createSurveyObj;
    // const chosenIndicators = getState().surveyCrud.createSurveyObj.chosenIndicators;
    // const profession = getState().surveyCrud.createSurveyObj.profession;
    // const quarter = getState().surveyCrud.createSurveyObj.quarter.value;
    // const designatedRecipient = getState().surveyCrud.createSurveyObj.designatedRecipient;
    // const wellnessCenterCheck = getState().surveyCrud.createSurveyObj.wellnessCenterCheck;
    // const year = getState().surveyCrud.createSurveyObj.year.value;
    // const appliedTo = getState().surveyCrud.createSurveyObj.appliedTo;
    // const surveyName = getState().surveyCrud.createSurveyObj.surveyName;
    // const isFixedOrder = getState().surveyCrud.createSurveyObj.fixedOrder;

    // UNUSED
    // const indicators = getState().surveyCrud.indicators.sort(() => 0.5 - Math.random());
    // const fileKey = getState().surveyCrud.createSurveyObj.s3Tags.fileKey;
    // const grades = getState().surveyCrud.grades;
    // const studentsByGrade = getState().surveyCrud.studentsByGrade;

    // let cleverStudents = [];
    // let isClever = false;
    // if (grades && grades.length > 0) {
    //   isClever = true;
    //   grades.forEach(grade => {
    //     cleverStudents = cleverStudents.concat(studentsByGrade[grade]);
    //     cleverStudents = cleverStudents.map(item => {
    //       if (item.contacts) {
    //         const parentWithEmail = item.contacts.find(item => item.email);
    //         if (parentWithEmail) {
    //           if (parentWithEmail.email) {
    //             item.parentEmail = parentWithEmail.email;
    //           }
    //           if (parentWithEmail.phone) {
    //             item.parentMobile = parentWithEmail.phone;
    //           }
    //         }
    //       }

    //       if (item.email) {
    //         item.participantEmail = item.email;
    //       }
    //       return item;
    //     });
    //   });
    //   participants = participants.concat(cleverStudents); // tack on clever students to manual participants
    // }

    // const indicatorsList = indicators.filter(indicator => {
    //   return indicator.appliesTo.includes(appliedTo);
    // });
    // const indicatorsByCategoryAndType = {};
    // const indicatorsByCategory = groupBy(indicatorsList, 'category');
    // Object.keys(indicatorsByCategory).forEach(group => {
    //   indicatorsByCategoryAndType[group] = groupBy(indicatorsByCategory[group], 'type');
    // });

    // const indicatorIdsByCategory = {};
    // // eslint-disable-next-line array-callback-return
    // Object.keys(indicatorsByCategory).map(key => {
    //   indicatorIdsByCategory[key] = indicatorsByCategory[key].map(item => item.indicatorId);
    // });

    // // eslint-disable-next-line array-callback-return
    // const categories = Object.keys(indicatorIdsByCategory).filter(categoryName => {
    //   if (indicatorIdsByCategory[categoryName].every(elem => chosenIndicators.indexOf(elem) > -1)) {
    //     return categoryName;
    //   }
    // });

    // if (categories.length === 0) {
    //   categories.push('selected');
    // }

    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data: {
        orgId, // TODO: get this from form instead?
        surveyName,
        fixedOrder,
        survey: {
          indicators: chosenIndicators,
          profession,
          quarter,
          year,
          appliedTo: appliedTo === 'k3' ? 'elementary' : appliedTo,
          categories: [], // just in case, not used any more
          ...(designatedRecipient && { designatedRecipient }),
          wellnessCenterCheck,
        },
        participants,
        // fileName: fileKey,
        // isClever,
      },
    })
      .then(res => {
        dispatch(createSurveySuccess(res.data));
        // dispatch(setStudentsByGrade(null, null)); // TODO : figure out what is it for?
        dispatch(listSurveys({ schoolId, districtId }));
        if (cb) {
          cb(res.data.surveyId);
        }
      })
      .catch(err => {
        dispatch(openToaster(`Error while creating survey`));
        console.log(err);
        dispatch(createSurveyFail(err));
      });
  };
};

export const getSurveyStart = () => {
  return {
    type: actionTypes.GET_SURVEY_START,
  };
};

export const getSurveySuccess = surveyDetails => {
  return {
    type: actionTypes.GET_SURVEY_SUCCESS,
    surveyDetails,
  };
};

export const getSurveyFail = err => {
  return {
    type: actionTypes.GET_SURVEY_FAIL,
    err,
  };
};

export const getSurveyOrgStart = () => {
  return {
    type: 'GET_SURVEY_ORG_START',
  };
};

export const getSurveyOrgSuccess = organization => {
  return {
    type: 'GET_SURVEY_ORG_SUCCESS',
    organization,
  };
};

export const getSurveyOrgFail = error => {
  return {
    type: 'GET_SURVEY_ORG_FAIL',
    error,
  };
};

export const getSurveyOrg = surveyItem => {
  return (dispatch, getState) => {
    dispatch(getSurveyOrgStart());
    const url = `${REACT_APP_API_URL}/org?orgId=${surveyItem.orgId}`;

    // NOTE: idToken being passed in from checkAuthState to avoid the race condition
    //    of the idToken not being set in state before this function is called on page load
    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        dispatch(getSurveyOrgSuccess(res.data));
        dispatch(getSurveySuccess(surveyItem));
      })
      .catch(err => {
        dispatch(getSurveyOrgFail(err));
      });
  };
};

export const getSurvey = surveyId => {
  return (dispatch, getState) => {
    const { role, idToken, orgId } = getState().auth;
    const navOrg = getState().navigation.org;
    const previousSurveyOrg = getState().surveyCrud.surveyOrg;
    dispatch(getSurveyStart());
    // const url = `${REACT_APP_API_URL}/survey/${surveyId}?lastKey=${lastKey}&sortKey=${sortKey}`;
    const url = `${REACT_APP_API_URL}/survey/${surveyId}`;
    axios({
      url,
      method: 'GET',
      headers: { Authorization: idToken },
    })
      .then(res => {
        // dispatch(getOrganization(res.data.orgId)); // why ??
        const authorized = role === 'tm_admin' || orgId === res.data.orgId || orgId === res.data.parentOrgId;
        if (authorized) {
          if (previousSurveyOrg?.orgId === res.data.orgId) { // previous survey was same org, no update needed
            dispatch(getSurveySuccess({ ...res.data }));
          } else if (navOrg?.orgId === res.data.orgId) { // the active organization is the one of the survey, copy data over
            dispatch(getSurveyOrgSuccess(navOrg));
            dispatch(getSurveySuccess({ ...res.data }));
          } else {
            dispatch(getSurveyOrg({ ...res.data }));
          }
        } else throw new Error(`You cannot access this assessment. orgId =${res.data.orgId}`);
      })
      .catch(err => {
        dispatch(getSurveyFail(err));
      });
  };
};

export const getAggregatedOverallReportStart = () => {
  return {
    type: actionTypes.GET_AGGREGATED_OVERALL_REPORT_START,
  };
};

export const getAggregatedOverallReportSuccess = reportDetails => {
  return {
    type: actionTypes.GET_AGGREGATED_OVERALL_REPORT_SUCCESS,
    reportDetails,
  };
};

export const getAggregatedOverallReportFail = err => {
  return {
    type: actionTypes.GET_AGGREGATED_OVERALL_REPORT_FAIL,
    err,
  };
};

export const getAggregatedOverallReport = () => {
  return (dispatch, getState) => {
    dispatch(getAggregatedOverallReportStart());
    const url = `${REACT_APP_API_URL}/reports/survey`;
    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        // console.log('GET REPORT RESPONSE: ', res);
        dispatch(getAggregatedOverallReportSuccess({ ...res.data.results }));
      })
      .catch(err => {
        console.log('ERROR WITH GETAGGREGATEDOVERALLREPORT: ', err);
        dispatch(getAggregatedOverallReportFail(err));
      });
  };
};

export const getAllParticipantDataStart = () => {
  return {
    type: actionTypes.GET_ALL_PARTICIPANT_DATA_START,
  };
};

export const getAllParticipantDataSuccess = surveyParticipants => {
  return {
    type: actionTypes.GET_ALL_PARTICIPANT_DATA_SUCCESS,
    surveyParticipants,
  };
};
export const getAllParticipantDataFail = err => {
  return {
    type: actionTypes.GET_ALL_PARTICIPANT_DATA_FAIL,
    err,
  };
};
export const getAllParticipantData = surveyId => {
  return async (dispatch, getState) => {
    dispatch(getAllParticipantDataStart());
    const url = `${REACT_APP_API_URL}/survey/${surveyId}/participants`;
    await axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        console.log('Survey Participant Response', res.data.participants);
        dispatch(getAllParticipantDataSuccess(res.data.participants));
      })
      .catch(err => {
        console.log('Error getting all survey participant data');
        dispatch(getAllParticipantDataFail(err));
      });
  };
};

export const getAllNotificationDataStart = () => {
  return {
    type: actionTypes.GET_ALL_NOTIFICATION_DATA_START,
  };
};

export const resetAllNotificationData = () => {
  return {
    type: actionTypes.RESET_ALL_NOTIFICATION_DATA,
  };
};

export const getAllNotificationDataSuccess = surveyNotificationData => {
  return {
    type: actionTypes.GET_ALL_NOTIFICATION_DATA_SUCCESS,
    surveyNotificationData,
  };
};
export const getAllNotificationDataFail = err => {
  return {
    type: actionTypes.GET_ALL_NOTIFICATION_DATA_FAIL,
    err,
  };
};

export const getAllNotificationData = surveyId => {
  return async (dispatch, getState) => {
    dispatch(getAllNotificationDataStart());
    const url = `${REACT_APP_API_URL}/survey/${surveyId}/notificationData`;
    await axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        console.log('Survey Notification Data Response', res.data);
        dispatch(getAllNotificationDataSuccess(res.data));
      })
      .catch(err => {
        console.log('Error getting all notification participant data');
        dispatch(getAllNotificationDataFail(err));
      });
  };
};

export const getAllNotificationResponsesStart = () => {
  return {
    type: actionTypes.GET_ALL_NOTIFICATION_RESPONSES_START,
  };
};

export const getAllNotificationResponsesSuccess = surveyNotificationResponses => {
  return {
    type: actionTypes.GET_ALL_NOTIFICATION_RESPONSES_SUCCESS,
    surveyNotificationResponses,
  };
};
export const getAllNotificationResponsesFail = err => {
  return {
    type: actionTypes.GET_ALL_NOTIFICATION_RESPONSES_FAIL,
    err,
  };
};

export const getAllNotificationResponses = surveyId => {
  return async (dispatch, getState) => {
    dispatch(getAllNotificationResponsesStart());
    const url = `${REACT_APP_API_URL}/survey/${surveyId}/notificationResponses`;
    await axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        console.log('Survey Notification Response Info: ', res.data);
        dispatch(getAllNotificationResponsesSuccess(res.data));
      })
      .catch(err => {
        console.log('Error getting all notification RESPONSE data');
        dispatch(getAllNotificationResponsesFail(err));
      });
  };
};

export const deleteSurveyStart = () => {
  return {
    type: actionTypes.DELETE_SURVEY_START,
  };
};

export const deleteSurveySuccess = () => {
  return {
    type: actionTypes.DELETE_SURVEY_SUCCESS,
  };
};

export const deleteSurveyFail = err => {
  return {
    type: actionTypes.DELETE_SURVEY_FAIL,
    err,
  };
};

export const deleteSurvey = (surveyId, cb) => {
  return (dispatch, getState) => {
    dispatch(deleteSurveyStart());
    const url = `${REACT_APP_API_URL}/survey/${surveyId}`;

    axios({
      url,
      method: 'DELETE',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        console.log('DELETE SURVEY RESPONSE: ', res);
        dispatch(deleteSurveySuccess());
        dispatch(closeModal());
        dispatch(openToaster('Successfully deleted survey.'));
        if (cb) {
          cb();
        }
      })
      .catch(err => {
        console.log('ERROR WITH DELETESURVEY: ', err);
        dispatch(deleteSurveyFail(err));
      });
  };
};
export const validateSurvey = (surveyId, cb) => {
  return (dispatch, getState) => {
    dispatch(validateSurveyStart());
    const url = `${REACT_APP_API_URL}/validateSurvey/${surveyId}`;

    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        console.log('VALIDATE SURVEY RESPONSE: ', res);
        dispatch(validateSurveySuccess());
        dispatch(getSurvey(surveyId));
        dispatch(closeModal());
        dispatch(openToaster('Successfully validated survey.'));
        if (cb) {
          cb();
        }
      })
      .catch(err => {
        console.log('ERROR WITH VALIDATESURVEY: ', err);
        dispatch(validateSurveyFail(err));
      });
  };
};
export const validateSurveyStart = () => {
  return {
    type: actionTypes.VALIDATE_SURVEY_START,
  };
};
export const validateSurveySuccess = () => {
  return {
    type: actionTypes.VALIDATE_SURVEY_SUCCESS,
  };
};

export const validateSurveyFail = err => {
  return {
    type: actionTypes.VALIDATE_SURVEY_FAIL,
    err,
  };
};
export const bulkUpdateParticipantStart = () => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_START,
  };
};

export const bulkUpdateParticipantSuccess = () => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_SUCCESS,
  };
};

export const bulkUpdateParticipantFail = err => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_FAIL,
    err,
  };
};

export const bulkUpdateParticipant = data => {
  return (dispatch, getState) => {
    const url = `${REACT_APP_API_URL}/participants`;
    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data,
    })
      .then(res => {
        console.log(res);
        dispatch(bulkUpdateParticipantSuccess());
        dispatch(closeModal());
        dispatch(getSurvey(data.surveyId));
      })
      .catch(err => {
        dispatch(bulkUpdateParticipantFail(err));
      });
  };
};
export const bulkAddResponderStart = () => {
  return {
    type: actionTypes.BULK_ADD_RESPONDER_START,
  };
};

export const bulkAddResponderSuccess = () => {
  return {
    type: actionTypes.BULK_ADD_RESPONDER_SUCCESS,
  };
};

export const bulkAddResponderFail = err => {
  return {
    type: actionTypes.BULK_ADD_RESPONDER_FAIL,
    err,
  };
};

export const bulkAddResponder = (participantIds, responderId) => {
  return (dispatch, getState) => {
    dispatch(bulkAddResponderStart());
    const url = `${REACT_APP_API_URL}/participants/${responderId}`;
    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data: { participantIds },
    })
      .then(res => {
        console.log(res);
        dispatch(bulkAddResponderSuccess());
      })
      .catch(err => {
        dispatch(bulkAddResponderFail(err));
      });
  };
};
export const bulkResponderCompleteStart = () => {
  return {
    type: actionTypes.BULK_RESPONDER_COMPLETE_START,
  };
};

export const bulkResponderCompleteSuccess = () => {
  return {
    type: actionTypes.BULK_RESPONDER_COMPLETE_SUCCESS,
  };
};

export const bulkResponderCompleteFail = err => {
  return {
    type: actionTypes.BULK_RESPONDER_COMPLETE_FAIL,
    err,
  };
};

export const bulkResponderComplete = participantIds => {
  return (dispatch, getState) => {
    dispatch(bulkResponderCompleteStart());
    const url = `${REACT_APP_API_URL}/participants/complete`;
    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data: { participantIds },
    })
      .then(res => {
        console.log(res);
        dispatch(bulkResponderCompleteSuccess());
      })
      .catch(err => {
        dispatch(bulkResponderCompleteFail(err));
      });
  };
};
export const removeResponderIdStart = () => {
  return {
    type: actionTypes.REMOVE_RESPONDER_ID_START,
  };
};

export const removeResponderIdSuccess = () => {
  return {
    type: actionTypes.REMOVE_RESPONDER_ID_SUCCESS,
  };
};

export const removeResponderIdFail = err => {
  return {
    type: actionTypes.REMOVE_RESPONDER_ID_FAIL,
    err,
  };
};

export const removeResponderId = id => {
  return (dispatch, getState) => {
    dispatch(removeResponderIdStart());
    const url = `${REACT_APP_API_URL}/participants/responder/${id}`;
    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        console.log(res);
        dispatch(removeResponderIdSuccess());
      })
      .catch(err => {
        dispatch(removeResponderIdFail(err));
      });
  };
};

export const bulkDeleteParticipantSuccess = () => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_SUCCESS,
  };
};

export const bulkDeleteParticipantFail = err => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_FAIL,
    err,
  };
};

export const bulkDeleteParticipant = data => {
  return (dispatch, getState) => {
    const url = `${REACT_APP_API_URL}/participants`;
    axios({
      url,
      method: 'DELETE',
      headers: { Authorization: getState().auth.idToken },
      data,
    })
      .then(() => {
        dispatch(bulkDeleteParticipantSuccess());
        dispatch(closeModal());
        dispatch(getSurvey(data.surveyId));
      })
      .catch(err => {
        dispatch(bulkDeleteParticipantFail(err));
      });
  };
};

export const updateParticipantStart = () => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_START,
  };
};

export const updateParticipantSuccess = () => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_SUCCESS,
  };
};

export const updateParticipantFail = err => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_FAIL,
    err,
  };
};

export const updateParticipant = (participantData, previousParticipant, responder = false) => {
  return async (dispatch, getState) => {
    dispatch(updateParticipantStart());
    // TODO : check what has changed and only update what's needed...
    // everything except consent
    const idToken = getState().auth.idToken;
    const url = `${REACT_APP_API_URL}/participant/${participantData.surveyParticipantId}`;
    let data = {
      participantStatus: participantData.participantStatus,
      contacted: participantData.contacted,
      groupName: participantData.groupName,
      notes: participantData.notes,
      participantEmail: participantData.participantEmail,
      referredTo: participantData.referredTo,
    };
    if (participantData.parentEmail) {
      data.parentEmail = participantData.parentEmail;
    }
    if (participantData.parentMobile) {
      data.parentMobile = participantData.parentMobile;
    }
    try {
      const res = await axios({
        url,
        method: 'POST',
        headers: { Authorization: idToken },
        data,
      });
      if (participantData.consentGranted !== previousParticipant.consentGranted && participantData.consentGranted !== undefined) {
        console.log(`consent changed`);
        const urlConsentApi = `${REACT_APP_API_URL}/v2/participant/${participantData.surveyParticipantId}/consentapi/${participantData.consentGranted? 'yes' : 'no'}`;
        await axios({
          url: urlConsentApi,
          method: 'GET',
          headers: { Authorization: idToken },
        });
      }
      dispatch(updateParticipantSuccess(res.data));
      dispatch(closeModal());
      if (!responder) {
        dispatch(getSurvey(res.data.participant.surveyId));
      }
    } catch (error) {
      dispatch(updateParticipantFail(error));
    }

  };
};

export const notifyParentSuccess = () => {
  return {
    type: actionTypes.NOTIFY_PARENT_REPORT_SUCCESS,
  };
};

export const notifyParentFail = err => {
  return {
    type: actionTypes.NOTIFY_PARENT_REPORT_FAIL,
    err,
  };
};

export const notifyParentReport = (participantData, surveyId) => {
  return (dispatch, getState) => {
    const url = `${REACT_APP_API_URL}/survey/notifications/notify-parent`;

    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data: {
        participant: participantData,
        surveyId: surveyId,
      },
    })
      .then(res => {
        console.log(res);
        dispatch(notifyParentSuccess());
        dispatch(closeModal());
        dispatch(getSurvey(surveyId));
      })
      .catch(err => {
        console.log('ERROR WITH NotifyParentReport: ', err);
        dispatch(notifyParentFail(err));
      });
  };
};

export const notifySurveyInviteSuccess = () => {
  return {
    type: actionTypes.NOTIFY_SURVEY_INVITE_SUCCESS,
  };
};

export const notifySurveyInviteFail = err => {
  return {
    type: actionTypes.NOTIFY_SURVEY_INVITE_FAIL,
    err,
  };
};

export const notifySurveyInvite = (participantData, surveyId) => {
  console.log('Notify Function Data: ', participantData);
  return (dispatch, getState) => {
    const url = `${REACT_APP_API_URL}/survey/notifications/notify-participant`;

    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data: {
        participant: participantData,
        surveyId: surveyId,
      },
    })
      .then(() => {
        dispatch(notifySurveyInviteSuccess());
        dispatch(closeModal());
        dispatch(getSurvey(surveyId));
      })
      .catch(err => {
        console.log('ERROR WITH NotifyParentReport: ', err);
        dispatch(notifySurveyInviteFail(err));
      });
  };
};

export const bulkNotifyParentSuccess = () => {
  return {
    type: actionTypes.BULK_NOTIFY_PARENT_REPORT_SUCCESS,
  };
};

export const bulkNotifyParentFail = err => {
  return {
    type: actionTypes.BULK_NOTIFY_PARENT_REPORT_FAIL,
    err,
  };
};

export const bulkNotifyParentReport = surveyId => {
  console.log('Bulk Notify Function Data: ', surveyId);
  return (dispatch, getState) => {
    const url = `${REACT_APP_API_URL}/reports/notify-parent/${surveyId}`;

    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data: {
        surveyId: surveyId,
      },
    })
      .then(res => {
        console.log(res);
        dispatch(bulkNotifyParentSuccess());
        dispatch(closeModal());
        dispatch(getSurvey(surveyId));
        dispatch(openToaster(`An email and/or text notification has been sent to all registered addresses.`));
      })
      .catch(err => {
        console.log('ERROR WITH BulkNotifyParentReport: ', err);
        dispatch(notifyParentFail(err));
      });
  };
};

export const bulkInviteParticipantSuccess = () => {
  return {
    type: actionTypes.BULK_INVITE_SUCCESS,
  };
};

export const bulkInviteParticipantFail = err => {
  return {
    type: actionTypes.BULK_INVITE_FAIL,
    err,
  };
};

export const bulkInviteParticipant = inviteParticipantsObject => {
  return (dispatch, getState) => {
    const url = `${REACT_APP_API_URL}/survey/notifications/bulk-notify/${inviteParticipantsObject.surveyId}`;

    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data: inviteParticipantsObject,
    })
      .then(res => {
        console.log(res);
        dispatch(bulkInviteParticipantSuccess());
        dispatch(closeModal());
        dispatch(getSurvey(inviteParticipantsObject.surveyId));
        dispatch(openToaster(`An email and/or text notification has been sent to all registered addresses.`));
      })
      .catch(err => {
        dispatch(notifyParentFail(err));
      });
  };
};

export const bulkPdfDownloadStart = () => {
  return {
    type: actionTypes.BULK_PDF_DOWNLOAD_START,
  };
};

export const bulkPdfDownloadDone = () => {
  return {
    type: actionTypes.BULK_PDF_DOWNLOAD_DONE,
  };
};

export const bulkSendPdf = sendPdfsObject => {
  return async (dispatch, _getState) => {
    dispatch(bulkPdfDownloadStart());

    let zip = new JSZip();
    const idToken = _getState().auth.idToken;
    await addAllPdfFilesToZipArchive(sendPdfsObject, zip, idToken);
    const blob = await zip.generateAsync({ type: 'blob' });
    const filename = `${new Date().toISOString().substring(0, 10)} ${sendPdfsObject.survey.surveyName.replace(/[^a-z0-9]/gi, '_')}.zip`;
    dispatch(bulkPdfDownloadDone());
    dispatch(closeModal());
    dispatch(openToaster(`Parent Reports have been saved to ${filename}. Check your Downloads folder`));
    saveAs(blob, filename);
  };
};

function partition(array, n) {
  return array.length ? [array.splice(0, n)].concat(partition(array, n)) : [];
}

function addAllPdfFilesToZipArchive(sendPdfsObject, zip, idToken) {
  return new Promise(async (resolve, reject) => {
    const promiseBatchs = partition(sendPdfsObject.participants, 20);
    for (const batch of promiseBatchs) {
      const promises = batch.map(async participant => {
        const url = `${REACT_APP_API_URL}/reports/pdf/parent/${participant.id}`;
        // const url = `https://htmlpdf.api.terracemetrics.org/report?pid=${participant.id}`;
        return axios({
          method: 'GET',
          url,
          responseType: 'blob',
          headers: { Accept: 'application/pdf', Authorization: idToken },
        })
          .then(blob => {
            const middleName = participant.middleName ? `-${participant.middleName}` : '';
            const studentId = participant.studentId ? `${participant.studentId.replace(/[^a-z0-9]/gi, '_').toLowerCase()}-` : '';
            zip.file(`${studentId}${participant.firstName}${middleName}-${participant.lastName}.pdf`, blob.data);
          })
          .catch(error => {
            reject(error);
          });
      });

      await Promise.all(promises).catch(reject);
    }

    resolve();
  });
}

export const getNotificationLog = (participantId, surveyId) => {
  return (dispatch, getState) => {
    dispatch(getNotificationLogStart());
    const url = `${REACT_APP_API_URL}/survey/notifications/log/${surveyId}/${participantId}`;
    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        console.log('All notification attempts', res.data.Items);
        dispatch(getNotificationLogSuccess(res.data.Items));
      })
      .catch(err => {
        console.log('Error getting all notification data');
        dispatch(getNotificationLogFail(err));
      });
  };
};

export const getNotificationLogStart = () => {
  return {
    type: actionTypes.GET_NOTIFICATION_LOG_START,
  };
};

export const getNotificationLogSuccess = notificationAttempts => {
  console.log('Notification Log Success: ', notificationAttempts);
  return {
    type: actionTypes.GET_NOTIFICATION_LOG_SUCCESS,
    notificationAttempts,
  };
};
export const getNotificationLogFail = err => {
  return {
    type: actionTypes.GET_NOTIFICATION_LOG_FAIL,
    err,
  };
};

export const adultNotifyReport = emailData => {
  return (dispatch, getState) => {
    console.log('ClientSide Event Data: ', emailData);
    const url = `${REACT_APP_API_URL}/reports/survey/participant/notify-adult`;

    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data: emailData,
    })
      .then(res => {
        console.log(res);
        dispatch(adultNotifyReportSuccess());
      })
      .catch(err => {
        console.log('ERROR WITH NotifyParentReport: ', err);
        dispatch(adultNotifyReportFail(err));
      });
  };
};

export const adultNotifyReportSuccess = () => {
  return {
    type: actionTypes.ADULT_NOTIFY_REPORT_SUCCESS,
  };
};

export const adultNotifyReportFail = err => {
  return {
    type: actionTypes.ADULT_NOTIFY_REPORT_FAIL,
    err,
  };
};

export const addParticipantToSurveyStart = () => {
  return {
    type: actionTypes.ADD_PARTICIPANT_TO_SURVEY_START,
  };
};

export const addParticipantToSurveySuccess = () => {
  return {
    type: actionTypes.ADD_PARTICIPANT_TO_SURVEY_SUCCESS,
  };
};

export const addParticipantToSurveyFail = err => {
  return {
    type: actionTypes.ADD_PARTICIPANT_TO_SURVEY_FAIL,
    err,
  };
};

// export const addParticipantToSurvey = participant => {
//   return (dispatch, getState) => {
//     dispatch(addParticipantToSurveyStart());
//     const { surveyId } = participant;
//     const { idToken } = getState().auth;
//     const url = `${REACT_APP_API_URL}/participant/survey/${surveyId}`;
//     axios({
//       url,
//       method: 'POST',
//       headers: { Authorization: idToken },
//       data: participant,
//     })
//       .then(res => {
//         console.log(res);
//         dispatch(addParticipantToSurveySuccess());
//         dispatch(closeModal());
//         dispatch(getSurvey(surveyId));
//         dispatch(openToaster(`${participant.firstName} ${participant.lastName} was successfully added to the survey.`));
//       })
//       .catch(err => {
//         console.log('ERROR WITH addParticipantToSurvey: ', err);
//         dispatch(addParticipantToSurveyFail(err));
//       });
//   };
// };

export const addParticipantToSurvey = (participant, surveyId) => {
  let data = JSON.stringify({
    participants: participant,
  });
  return (dispatch, getState) => {
    dispatch(addParticipantToSurveyStart());
    const { idToken } = getState().auth;
    const url = `${REACT_APP_API_URL}/bulkAddParticipants/${surveyId}`;
    axios({
      url,
      'Content-Type': 'application/json',
      method: 'POST',
      headers: { Authorization: idToken },
      data: data,
    })
      .then(() => {
        dispatch(addParticipantToSurveySuccess());
        dispatch(closeModal());
        dispatch(getSurvey(surveyId));
        dispatch(openToaster('Participants successfully added to the survey.'));
      })
      .catch(err => {
        console.log('ERROR WITH addParticipantToSurvey: ', err);
        dispatch(addParticipantToSurveyFail(err));
      });
  };
};

export const deleteParticipantFromSurveyStart = () => {
  return {
    type: actionTypes.DELETE_PARTICIPANT_FROM_SURVEY_START,
  };
};

export const deleteParticipantFromSurveySuccess = () => {
  return {
    type: actionTypes.DELETE_PARTICIPANT_FROM_SURVEY_SUCCESS,
  };
};

export const deleteParticipantFromSurveyFail = err => {
  return {
    type: actionTypes.DELETE_PARTICIPANT_FROM_SURVEY_FAIL,
    err,
  };
};

export const deleteParticipantFromSurvey = (participantId, surveyId) => {
  return (dispatch, getState) => {
    dispatch(deleteParticipantFromSurveyStart());
    const url = `${REACT_APP_API_URL}/participant/${participantId}`;

    axios({
      url,
      method: 'DELETE',
      headers: { Authorization: getState().auth.idToken },
    })
      .then(res => {
        console.log(res);
        dispatch(deleteParticipantFromSurveySuccess());
        dispatch(closeModal());
        dispatch(getSurvey(surveyId));
      })
      .catch(err => {
        dispatch(addParticipantToSurveyFail(err));
      });
  };
};

export const downloadSurveys = surveyListIds => {
  return (dispatch, getState) => {
    dispatch(bulkPdfDownloadStart());
    const url = `${REACT_APP_API_URL}/survey/notificationData`;
    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data: {
        surveyIds: surveyListIds,
      },
    })
      .then(res => {
        console.log(res);
        const erl = res.data.url;
        console.log(erl);
        axios({
          url: erl,
          method: 'GET',
          crossDomain: true,
          headers: { 'Access-Control-Allow-Origin': '*' },
        }).then(resl => {
          exportCSVFile(resl.data, 'surveys_download.csv');
          dispatch(bulkPdfDownloadDone());
        });
      })
      .catch(err => {
        console.log('[downloadSurveys][ERROR] WITH downloadSurveys: ', err);
      });
  };
};

async function exportCSVFile(items, fileTitle) {
  console.log('[exportCSVFile] CSV Items to Export');
  let exportedFilename = fileTitle + '.csv' || 'export.csv';
  var link = document.createElement('a');
  var blob = new Blob([items], { type: 'text/csv;charset=utf-8;' });
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    var url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', exportedFilename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
