import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input } from 'reactstrap';
import TableGenerator from '../../../../../components/TableGenerator/TableGenerator';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import CheckedImage from '../../../../../assets/imgs/checked.png';
import UncheckedImage from '../../../../../assets/imgs/unchecked.png';
import { dateFormat, adjustDate } from '../../../../../utilities/dates';
import * as actions from '../../../../../store/actions';
import Overlay from '../../../../../components/Overlay/overlay';
import Spinner from '../../../../../components/Spinner/Spinner';

const BulkInviteModal = ({ participants, show, surveyId,surveyDetails }) => {
  const [participantList, setParticipantList] = useState([]);
  const [searchedParticipants, setSearchedParticipants] = useState([]);
  const [search, setSearch] = useState('');
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialParticipants = participants
      .filter(participant => 
        (participant.participantStatus === 'Not Started' || participant.participantStatus === 'Not Completed') &&
        (participant.consentGranted === true || surveyDetails.consentType == null)
      ).sort((p,q) => p.lastName.localeCompare(q.lastName))
      .map((participant, index) => ({
        ...participant,
        checked: false,
        position: index
      }));
    setParticipantList(initialParticipants);
  }, [participants]);

  const handleSubmit = () => {
    setIsLoading(true);
    const surveyParticipantIds = participantList
      .filter(participant => participant.checked)
      .map(participant => participant.surveyParticipantId);
    const inviteParticipantsObject = { participantIds: surveyParticipantIds, surveyId };
    dispatch(actions.bulkInviteParticipant(inviteParticipantsObject));
  };

  const searchForNames = (e) => {
    const value = e.target.value.toLowerCase();
    const filteredParticipants = participantList.filter(
      participant =>
        participant.firstName.toLowerCase().includes(value) ||
        participant.lastName.toLowerCase().includes(value) ||
        `${participant.firstName.toLowerCase()} ${participant.lastName.toLowerCase()}`.includes(value)
    );

    setSearchedParticipants(filteredParticipants);
    setSearch(e.target.value);
  };

  const handleClick = (index) => {
    setParticipantList(prevParticipants => {
      return prevParticipants.map(participant => {
        const isMatch = search 
          ? participant.position === searchedParticipants[index]?.position 
          : participant.position === index;

        if (isMatch) {
          participant.checked = !participant.checked;
        }
        return participant;
      });
    });
  };

  const generateTableData = () => {
    const data = (search ? searchedParticipants : participantList).map(participant => ({
      name: `${participant.lastName}, ${participant.firstName}`,
      DOB: adjustDate(participant.birthDate).format(dateFormat),
      selected: (
        <img 
          className="checkbox" 
          style={{ cursor: 'pointer' }} 
          alt="Checkbox" 
          src={participant.checked ? CheckedImage : UncheckedImage} 
        />
      ),
    }));
    return data;
  };

  const handleIsSelectAll = () => {
    setIsSelectAll(prevState => !prevState);
    setParticipantList(prevParticipants => 
      prevParticipants.map(participant => ({ ...participant, checked: !isSelectAll }))
    );
  };

  return (
    <Overlay
      className="survey-status-modal-wrapper"
      isOpen={show}
      onToggle={() => dispatch(actions.closeModal())}
      title="Bulk Invite Participants"
    >
      <div className='p-4'>
        <Input 
          className="form-control" 
          style={{ border: '1px solid #CACACA' }} 
          type="text" 
          onChange={searchForNames} 
          value={search} 
          placeholder='Search participants'
        />
        <CheckBox
          className="select-all-invite-container"
          labelClasses={['select-all-invite']}
          label="Select All"
          name="Select All"
          value="Select All"
          isChecked={isSelectAll}
          onClick={handleIsSelectAll}
        />
        <div className="modal-table-container">
          <TableGenerator
            config={{
              headers: ['PARTICIPANT', 'DATE OF BIRTH', ''],
              contentRows: generateTableData(),
              rowFunction: handleClick,
              itemsPerPage: 1000000,
            }}
          />
        </div>
        <div className="btnContainer-edit-participant">
          <Button onClick={handleSubmit} type="submit" className="small-button" size="md">
            {isLoading && <Spinner />} Invite Participants
          </Button>
          <Button onClick={() => dispatch(actions.closeModal())} className="small-blue-button-outlined" size="md">
            Cancel
          </Button>
        </div>
      </div>
    </Overlay>
  );
};

export default BulkInviteModal;
