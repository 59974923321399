import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Papa from 'papaparse'
import * as actions from '../../../../../store/actions';
import Spinner from '../../../../../components/Spinner/Spinner';

const StudentRosterUpdate = (props) => {
  const [state, setState] = useState({
    cleverFormattedRoster: undefined,
    intervalId: null,
    fileName: null,
    error: null,
    isWaiting: false,

    inputAddParticipantMethod: undefined,
    participants: [],
    csvProcessSummary: undefined,
    readyToSubmit: false,
    cleverSelectedGrades: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const requiredFields = ["firstName", "lastName", "birthDate", "gender"];
  const optionalFields = ["middleName", "grade", "studentId", "parentEmail", "participantEmail"];

  const validateParticipant = (item) => {
    const allRequiredPresent = requiredFields.every(rf => item[rf] !== '')
    return allRequiredPresent;
  }

  const formatRosterPerGrade = (roster) => {
    const formattedRoster = { "total": roster.length, "perGrade": {} };
    roster.forEach(student => {
      (!formattedRoster.perGrade.hasOwnProperty(student.grade)) && (formattedRoster.perGrade[student.grade] = []);
      formattedRoster.perGrade[student.grade].push(student);
    })
    return formattedRoster
  }
  // File Upload Method
  const processPapaResults = (results) => {
    const validRosterEntries = []
    const processSummary = { csvRosterPerGrade: [], invalidRows: [], comment: [] }
    const allValidFields = [...requiredFields, ...optionalFields];
    // check if some fields are invalid
    if (results.errors.length > 0) {
      processSummary['status'] = "error";
      processSummary['error'] = results.errors.map(e => `Row: ${e.row}, type:${e.type}, code: ${e.code}, message: ${e.message}`).join("\n");
    } else {
      processSummary['status'] = "success";
      if (!results.meta.fields.every(f => allValidFields.includes(f))) {
        processSummary['comment'].push("Some fields will be ignored");
      }
      results.data.forEach((parsedParticipant, rowIndex) => {
        if (!validateParticipant(parsedParticipant)) {
          processSummary.invalidRows.push(rowIndex);
        } else {
          validRosterEntries.push(parsedParticipant);

          setState(prevState => ({
            ...prevState,
            // participants: [parsedParticipant]
          }));
        }
      });
      if (processSummary.invalidRows.length > 0) {
        processSummary['comment'].push('These rows have invalid data: ' + processSummary.invalidRows.join(","));
      }
    }
    processSummary.csvRosterPerGrade = formatRosterPerGrade(validRosterEntries);
    processSummary.validRosterEntriesCount = validRosterEntries.length;
    setState(prevState => ({
      ...prevState,
      csvProcessSummary: { ...prevState.csvProcessSummary, ...processSummary }
    }));
  }

  const handleFileChange = (event) => {
    const ROSTER_MAX_SIZE = 300000 // (300kB)
    const filename = event.target.files[0].name;
    setState(prevState => ({
      ...prevState,
      csvProcessSummary: { filename: filename }
    }));
    if (event.target.files[0].size > ROSTER_MAX_SIZE) {
      setState(prevState => ({
        ...prevState,
        csvProcessSummary: { ...prevState.csvProcessSummary, status: "error", error: `File is over the size limit of ${Math.floor(ROSTER_MAX_SIZE/1000)} kB` }
      }));
    } else {
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: processPapaResults,
        transformHeader: h => {
          const headerMap = {
            "notifyEmail": "parentEmail"
          };
          return (headerMap.hasOwnProperty(h)) ? headerMap[h] : h
        }
      });
    }
  };

  const cleverGradeCheckboxChange = (event) => {
    const newSelectedGrades = state.cleverSelectedGrades
    const index = newSelectedGrades.indexOf(event.target.value);
    if (event.target.checked) {
      (index > -1) || newSelectedGrades.push(event.target.value)
    } else {
      (index > -1) && newSelectedGrades.splice(index, 1)
    }
    let participants = []
    newSelectedGrades.forEach(grade => {
      participants = [...participants, ...(state.inputAddParticipantMethod === "addParticipantClever" ? state.cleverFormattedRoster.perGrade[grade] : state.csvProcessSummary.csvRosterPerGrade.perGrade[grade])]
    });
    setState(prevState => ({
      ...prevState,
      cleverSelectedGrades: newSelectedGrades,
      participants: participants
    }));
  }

  const GradeSelector = ({ perGradeRoster, selectedGrades, onCheckboxChange }) => {
    const checkBoxes = Object.keys(perGradeRoster.perGrade).map((grade) =>
      <div key={grade} className="form-check">
        <input className="form-check-input" type="checkbox" checked={selectedGrades.includes(grade)} onChange={onCheckboxChange} value={grade} id={`gradeSelectorCheckbox-${grade}`} />
        <label className="form-check-label" htmlFor={`gradeSelectorCheckbox-${grade}`}>
          Grade {grade} ({perGradeRoster.perGrade[grade].length} students)
        </label>
      </div>
    );
    return checkBoxes;
  }
  const handleCSVSubmit = () => {
    setIsLoading(true)
    const parsedParticipants = state.participants;
    props.onSubmit(parsedParticipants)
  }
  return (
    <div className="file-input-create-survey-step-group">
        <div className="input-group-student-roster">
          {!state.csvProcessSummary &&
          <div className='yellow'>Click in the box below to select a CSV file on your computer.</div>
         }
         { state.csvProcessSummary?.status === "error" && state.csvProcessSummary.error && <>
          <div className='text-danger'>{state.csvProcessSummary.error}</div>
         </>}
          {!state.csvProcessSummary || state.csvProcessSummary.status !== "success"
            ? <>
              <input
                type="file"
                name="file"
                accept=".csv"
                onChange={handleFileChange}
                style={{ display: "block", margin: "10px auto" }}
                className='input-group-student-roster__input' />
            </>
            : <>
              <p>File <code>{state.csvProcessSummary.filename}</code> has been processed successfully: {state.csvProcessSummary.validRosterEntriesCount} participants are ready to be added  ( {state.csvProcessSummary.invalidRows.length} invalid were ignored)</p>
              <GradeSelector 
                perGradeRoster={state.csvProcessSummary.csvRosterPerGrade} 
                selectedGrades={state.cleverSelectedGrades} 
                onCheckboxChange={cleverGradeCheckboxChange} 
              />
            </>}
            {state.csvProcessSummary && state.csvProcessSummary.status === "success" && <div className='yellow bold'>
            Select one or more grades to add to survey.
            </div>}
        {!!state.participants.length &&  <Button
            type="submit"
            className='small-button mt-4'
            onClick={handleCSVSubmit}
            disabled={state.participants.length == 0}
          >
           {isLoading && <Spinner/>} Add {state.participants.length > 0 ? state.participants.length : null} participants
          </Button>}
        </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isRosterUploading: state.surveyCrud.isRosterUploading,
    manualRoster: state.surveyCrud.manualRoster,
    // user: state.auth.isAuthenticated,
    organization: state.organizations.organization,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSignedUrl: file => dispatch(actions.getSignedUrl(file)),
    uploadRoster: file => dispatch(actions.uploadRoster(file)),
    checkRosterUploadStatus: () => dispatch(actions.checkRosterUploadStatus()),
    addToManualRosterLength: () => dispatch(actions.addToManualRosterLength()),
    createSurvey: (participants, cb) => dispatch(actions.createSurvey(participants, cb)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudentRosterUpdate);
